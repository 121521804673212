import React, { useEffect } from 'react';

const D1Storelocator = () => {
	useEffect(() => {
		(function(){const a=document.createElement('script');a.type='text/javascript';a.async=!0;a.src='https://cdn.storepoint.co/api/v1/js/16305cd79232f6.js';const b=document.getElementsByTagName('script')[0];b.parentNode?.insertBefore(a,b);}());
	}, []);

	return (
		<section className='storelocator'>
			<div id='storepoint-container' data-map-id='16305cd79232f6'></div>
		</section>
	);};

export default D1Storelocator;
